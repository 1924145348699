<template>
  <div class="apply-container">
    <van-nav-bar
      :title="$t('appointment.title')"
      :left-text="$t('back')"
      left-arrow
      @click-left="toOrder"
    >
    </van-nav-bar>
    <div class="apply-content">
      <div class="apply-gap"></div>
      <div class="apply-section">
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('appointment.subject')}}</label>
          <input :placeholder="$t('appointment.subject')" v-model="order.title" />
        </div>
      </div>
      <div class="apply-section">
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('form.selectDipm')}}</label>
          <van-cell is-link :title="order.dipm_name || $t('form.selectDipmTip')" @click="showDipm = true" :class="{ 'c-gray': !order.dipm_id}" />
        </div>
        <div class="apply-item van-hairline--bottom">
          <label required>{{$t('appointment.time')}}</label>
          <van-cell is-link :title="order.timeStr || $t('appointment.time')" @click="toggleRange" :class="{ 'c-gray': !order.start}" />
        </div>
        <van-action-sheet
          v-model="showDipm"
          :actions="dipmList"
          :cancel-text="$t('form.cancel')"
          close-on-click-action
          @select="selectDipm"
        />
        <van-popup v-model="showRange" round position="bottom">
          <van-picker
            show-toolbar
            :columns="timeRange"
            @cancel="showRange = false"
            @confirm="onConfirm"
          />
        </van-popup>
      </div>
    </div>
    <div class="apply-submit">
      <van-button block round type="warning" :loading="loading" :loading-text="$t('submiting')" @click="onSubmit">{{$t('submit')}}</van-button>
    </div>
  </div>
</template>

<script type="text/javascript">
import room from '@/api/room'
import dayjs from 'dayjs'
export default {
  data() {
    return {
      order: {},
      dipmList: [
        { id: 1, name: '中华人民共和国驻瑞典大使馆' },
        { id: 7, name: '中华人民共和国驻哥德堡总领事馆' }
      ],
      timeRange: [],
      showDipm: false,
      showRange: false,
      loading: false,
    }
  },
  created() {
    room.getList()
  },
  methods: {
    toggleRange() {
      if (!this.order.dipm_id) {
        this.$toast(this.$t('form.selectDipmTip'))
        return;
      }
      if (!this.timeRange.length) {
        this.$toast(this.$t('appointment.emptyTip'))
        return;
      }
      this.showRange = true
    },
    getTimeStr() {
      if (!this.order.start || !this.order.end) return ''
      return dayjs(this.order.start).format('YYYY-MM-DD HH:mm ~ ') + dayjs(this.order.end).format('HH:mm')
    },
    selectDipm(item) {
      this.order.dipm_id = item.id
      this.order.dipm_name = item.name
      this.order.start = ''
      this.order.end = ''
      this.order.timeStr = ''
      this.getTimeRange()
    },
    handleShowRange() {
      if (!this.order.dipm_id) {
        this.$toast(this.$t('form.selectDipmTip'))
        return;
      }
      this.showRange = true
    },
    getTimeRange() {
      const cb = this.$toast.loading({
        message: this.$t('loading'),
        duration: 0,
        forbidClick: true
      })
      room.permission(this.order.dipm_id)
      .then(res => {
        let range = []
        let rangeMap = {}
        let list = (res || []).sort((a, b) => dayjs(a.start) - dayjs(b.start))
        for (let item of list) {
          let date = dayjs(item.start).format('YYYY-MM-DD')
          if (!rangeMap[date]) {
            rangeMap[date] = range.push({
              text: date,
              children:[] 
            })
          }
          range[rangeMap[date] - 1].children.push({
            text: dayjs(item.start).format('HH:mm') + '-' + dayjs(item.end).format('HH:mm'),
            ...item
          })
        }
        this.timeRange = range
        cb.clear()
        if (!range.length) {
          this.$toast(this.$t('appointment.emptyTip'))
        }
      })
      .catch(() => {
        cb.clear()
      })
    },
    onConfirm(value) {
      if (!value || value.length !== 2) {
        this.$toast(this.$t('appointment.time'))
        return false
      }
      let arr = value[1].split('-')
      this.order.start = value[0] + ' ' + arr[0]
      this.order.end = value[0] + ' ' + arr[1]
      this.order.timeStr = this.getTimeStr()
      this.showRange = false
    },
    toOrder() {
      this.$router.push('/appointment-list')
    },
    onSubmit() {
      const { order } = this
      if (!order.title) {
        this.$toast(this.$t('appointment.subject') + this.$t('required'))
        return false
      }
      if (!order.dipm_id) {
        this.$toast(this.$t('form.selectDipmTip'))
        return false
      }
      if (!order.timeStr) {
        this.$toast(this.$t('appointment.time') + this.$t('required'))
        return false
      }
      this.loading = true
      const body = {
        start: order.start,
        end: order.end,
        dipm_id: order.dipm_id,
        title: order.title
      };
      room.add(body)
      .then(() => {
        this.loading = false
        this.$toast.success(this.$t('form.successTip'))
        this.$router.push(`/appointment-list`)
      })
      .catch(() => {
        this.loading = false
      })
    }
  }
}
</script>
